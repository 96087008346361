import classNames from "classnames";

export const Input = ({ title, id, value, onChange, className, error, ...props }) => {
	return (
		<div className="border-0 border-transparent p-0 mb-4 sm:mb-8" {...props}>
			<label htmlFor={id} className="block text-sm sm:text-lg md:text-xl font-medium">
				{title}
			</label>
			<div className="mt-1 relative rounded-sm">
				<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<span className="text-gray-500 sm:text-sm">$</span>
				</div>
				<input
					type="text"
					name={title}
					id={id}
					data-testid={id}
					value={value !== "N/A" ? value : ""}
					onChange={onChange}
					className={classNames(
						!error && "hover:border-blue focus:border-blue  border-gray-300",
						error && "border border-red-400",
						"block w-full pl-7 pr-12 text-base md:text-lg rounded-sm cursor-pointer",
						classNames
					)}
					placeholder="0.00"
				/>
			</div>
		</div>
	);
};
