import { ReactComponent as Logo } from "./assets/images/athena.svg";
import { ReactComponent as GetStarted } from "./assets/images/get-started.svg";
import { Input, Button, LVRCalculation, Error } from "./components";
import * as React from "react";
import numeral from "numeral";
import { calculateLVR } from "./utils";

numeral.zeroFormat("N/A");
numeral.nullFormat("N/A");

const errors = {
	propertyValue: "Enter a value between 100,000 and 2,500,000",
	borrowAmount: "Enter a value between 80,000 and 2,000,000",
};

export default function Main() {
	const [inputs, setInputs] = React.useState({ estimatedPropertyValue: undefined, borrowAmount: undefined });
	const [loanToValueRatio, setLoanToValueRatio] = React.useState("- -%");
	const [error, setError] = React.useState("");

	React.useEffect(() => {
		let epv = sessionStorage.getItem("estimatedPropertyValue");
		let ba = sessionStorage.getItem("borrowAmount");
		setInputs({ estimatedPropertyValue: epv, borrowAmount: ba });
	}, []);

	const handleChange = (name) => (event) => {
		const { value } = event.target;
		let val = numeral(value);
		setInputs({ ...inputs, [name]: val.value() });
		setLoanToValueRatio("- -%");
		setError("");
		sessionStorage.setItem(`${[name]}`, `${val.value()}`);
	};

	const checkErrors = () => {
		if (inputs.estimatedPropertyValue < 100000 || inputs.estimatedPropertyValue > 2500000 || inputs.estimatedPropertyValue === undefined) {
			setError("propertyValue");
			return true;
		}
		if (inputs.borrowAmount < 80000 || inputs.borrowAmount > 2000000 || inputs.borrowAmount === undefined) {
			setError("borrowAmount");
			return true;
		}
		return false;
	};

	const handleCalculateLVR = (event) => {
		event.preventDefault();
		let epv = numeral(inputs.estimatedPropertyValue).value();
		let ba = numeral(inputs.borrowAmount).value();
		let e = checkErrors();
		if (!e) setLoanToValueRatio(calculateLVR(epv, ba));
	};

	return (
		<div className="bg-white px-4 overflow-hidden md:px-6 max-w-screen-xl mx-auto">
			<div className="relative">
				<nav className="relative flex items-center justify-between h-12 md:h-14 mb-6 md:mb-10">
					<div className="pt-2 md:pt-4 cursor-pointer">
						<Logo />
					</div>
					<div className="cursor-pointer">
						<GetStarted />
					</div>
				</nav>
			</div>
			<main className="mx-auto my-4 py-12 flex">
				<div className="w-1/2 pr-2 md:pr-4 pt-2 sm:pt-10">
					<div className="text-left flex flex-col justify-between h-full">
						<h1 className="text-3xl sm:text-4xl md:text-6xl font-extrabold text-athena mb-2 md:mb-6">Calculate your LVR</h1>
						<p className="mb-8 sm:mb-16 text-sm sm:text-xl md:text-3xl">Enter the values below to calculate your loan to value ratio.</p>
						<Input
							title="Estimated property value"
							id="estimated-property-value"
							error={error === "propertyValue"}
							value={numeral(inputs.estimatedPropertyValue).format("0,0")}
							onChange={handleChange("estimatedPropertyValue")}
						/>
						{error === "propertyValue" && <Error error={errors[error]} id="estimated-property-value-error" />}
						<Input
							title="Borrow amount"
							id="borrow-amount"
							className="mb-8"
							error={error === "borrowAmount"}
							value={numeral(inputs.borrowAmount).format("0,0")}
							onChange={handleChange("borrowAmount")}
						/>
						{error === "borrowAmount" && <Error error={errors[error]} id="borrow-amount-error" />}
						<div className="mt-4 md:mt-8 flex justify-start">
							<Button title="calculate" id="calculate-lvr" data-testid="calculate-lvr" onClick={(event) => handleCalculateLVR(event)} />
						</div>
					</div>
				</div>
				<div className="w-1/2 pl-4">
					<LVRCalculation loanToValueRatio={loanToValueRatio} />
				</div>
			</main>
		</div>
	);
}
