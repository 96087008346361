import { ReactComponent as LVR } from "../../assets/images/IC-HACKS.svg";
import * as React from "react";
import classNames from "classnames";

export const LVRCalculation = ({ className, loanToValueRatio, ...props }) => {
	return (
		<div
			className={classNames(
				"relative rounded-md bg-athena-light w-full h-full flex flex-col sm:justify-center md:justify-between p-4 md:p-16",
				className
			)}
			{...props}
		>
			<div className="flex items-center justify-center my-8">
				<LVR className="h-2/3 sm:h-4/5 md:h-full" />
			</div>
			<p className="mx-auto text-4xl sm:text-5xl md:text-6xl font-extrabold my-6">Your LVR is</p>
			<p data-testid="lvr-result" id="lvr-result" className="mx-auto text-6xl md:text-8xl font-extrabold text-athena my-6">
				{loanToValueRatio}
			</p>
		</div>
	);
};
