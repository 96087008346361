export const Button = ({ title, id, ...props }) => {
	return (
		<button
			type="button"
			id={id}
			data-testid={id}
			className="w-auto flex items-center justify-center px-8 md:px-10 py-2 md:py-3 border border-transparent text-base md:text-lg font-bold rounded-sm text-white bg-blue hover:bg-athena  uppercase tracking-wider shadow"
			{...props}
		>
			{title}
		</button>
	);
};
